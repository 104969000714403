import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import type { Input } from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'

import { generateBacsForm } from './generators/generateBacsForm'
import { generateIbanForm } from './generators/generateIbanForm'
import { useBuybackConfig } from './useBuybackConfig'
import translations from './useGenerateFunnelBankDetailsForm.translations'

export const useGenerateFunnelBankDetailsForm = (): Array<Input> => {
  const i18n = useI18n()

  const marketConfig = useBuybackConfig()

  return [
    ...((marketConfig?.iban && generateIbanForm()) || []),
    ...((marketConfig?.bacs && generateBacsForm()) || []),
    {
      id: 'skip',
      cols: 6,
      type: 'checkbox',
      validation: [],
      label: i18n(translations.formNoRibCheck),
      value: false,
      extraCss: ['w-full', 'mt-22'],
    },
  ]
}
