export default {
  headerTitle: {
    id: 'buyback_bank_header_title',
    defaultMessage: '1/3 Informations Bancaires',
  },
  formTitle: {
    id: 'buyback_bank_form_title',
    defaultMessage: 'Nous en avons besoin pour vous payer',
  },
  formDescription: {
    id: 'buyback_bank_form_description',
    defaultMessage:
      'Comme ça on pourra verser {price, number, currency} directement sur votre compte en banque. Bim, bam, boum !',
  },
  buttonLabel: {
    id: 'buyback_bank_form_submit',
    defaultMessage: 'Submit',
  },
}
