import { createHttpEndpoint } from '../../utils'

/**
 * Retrieve Client's bank details from wallet
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition#tag/wallets/operation/retrieveClientWalletDetails}
 */
export const postWallet = createHttpEndpoint({
  method: 'POST',
  operationId: 'saveIbanBic',
  path: '/bm/client/wallet',
})
