import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import bacsTranslations from '~/scopes/buyback/config/generators/generateBacsForm.translations'
import ibanTranslations from '~/scopes/buyback/config/generators/generateIbanForm.translations'

import translations from './useBankApiErrors.translations'

export interface BankApiErrors {
  data: {
    fields: Record<string, Array<string>>
    global: string[] | null
  }
  error: {
    code: string
    message: string
  }
  errors: Array<{
    type: string
    title: string
    target: string
  }>
  requestId: string
  status: number
  title: string
  type: string
}

interface BankError {
  bic?: string
  iban?: string
  accountNumber?: string
  routingNumber?: string
  sortCode?: string
  error?: string
}

export const useBankApiErrors = (errors: BankApiErrors) => {
  const i18n = useI18n()
  const apiErrors: BankError = {}
  const hasServerErrors =
    Object.prototype.hasOwnProperty.call(errors, 'data') &&
    Object.prototype.hasOwnProperty.call(errors.data, 'fields') &&
    Object.entries(errors.data.fields)?.length

  if (hasServerErrors) {
    const serverErrors = errors?.data?.fields

    if (serverErrors?.bic) {
      apiErrors.bic = i18n(ibanTranslations.formBicError)
    }
    if (serverErrors?.iban) {
      apiErrors.iban = i18n(ibanTranslations.formIbanError)
    }
    if (serverErrors?.accountNumber) {
      apiErrors.accountNumber = i18n(bacsTranslations.formAccountNumberError)
    }
    if (serverErrors?.routingNumber) {
      apiErrors.routingNumber = i18n(bacsTranslations.formRoutingNumberError)
    }
    if (serverErrors?.sortCode) {
      apiErrors.sortCode = i18n(bacsTranslations.formSortCodeError)
    }
  } else if (errors?.error) {
    apiErrors.error = i18n(translations.formApiError)
  }

  return apiErrors
}
