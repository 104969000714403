import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { FORM_VALID } from '@ds/components/Form'
import type { InputValue } from '@ds/types/InputValue'
import {
  electronicFormatIBAN,
  friendlyFormatIBAN,
  isValidBIC,
  isValidIBAN,
} from 'ibantools'

import type {
  FormValues,
  InputText,
} from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'

import translations from './generateIbanForm.translations'

export const generateIbanForm = (): Array<InputText> => {
  const i18n = useI18n()

  const errorIban = i18n(translations.formIbanError)
  const errorBic = i18n(translations.formBicError)

  return [
    {
      id: 'iban',
      cols: 6,
      type: 'text',
      label: i18n(translations.formIban),
      description: i18n(translations.formIbanDescription),
      value: '',
      validation: [
        function validateIban(iban: string, form: FormValues) {
          const formattedIban = electronicFormatIBAN(iban.toUpperCase()) || ''

          return isValidIBAN(formattedIban) || form.skip
            ? FORM_VALID
            : errorIban
        },
      ],
      formatter: (inputEvent: InputValue) => {
        if (typeof inputEvent === 'string') {
          return friendlyFormatIBAN(inputEvent) || ''
        }

        return inputEvent
      },
      extraCss: ['mb-8'],
    },
    {
      id: 'bic',
      cols: 6,
      type: 'text',
      label: i18n(translations.formBic),
      description: i18n(translations.formBicDescription),
      value: '',
      validation: [
        function validateBic(bic: string, form: FormValues) {
          return isValidBIC(bic.toUpperCase()) || form?.skip
            ? FORM_VALID
            : errorBic
        },
      ],
    },
  ]
}
