export default {
  formIban: {
    id: 'buyback_bank_form_iban',
    defaultMessage: 'IBAN',
  },
  formBic: {
    id: 'buyback_bank_form_bic',
    defaultMessage: 'BIC',
  },
  formIbanDescription: {
    id: 'buyback_bank_form_iban_description',
    defaultMessage: 'Ex : FR14 2004 1010 2004 1010 1010',
  },
  formBicDescription: {
    id: 'buyback_bank_form_bic_description',
    defaultMessage: 'EX : DABADEI2',
  },
  formIbanError: {
    id: 'buyback_bank_form_iban_error',
    defaultMessage: 'Error in Iban',
  },
  formBicError: {
    id: 'buyback_bank_form_bic_error',
    defaultMessage: 'Error in Bic',
  },
}
